.container {
  width: 100%;
  @media (--large) {
    width: auto;
  }
}

.option {
  padding: 4px 10px;
  cursor: pointer;

  &:hover {
    background: var(--color-tertiary);
  }
}
.svg {
  margin-right: 10px;
}